import React from 'react';

import { getLogger } from '@dirico/utils/Logger';
import ResourceLoader from '@dirico/utils/ResourceLoader';

import type ApplicationInjector from '@dirico/application-core/services/ApplicationInjector';

/** Creates a new lazy application root component which also has a dispose function linked for use with hot-reload */
export default function createApplicationRoot(
    loaderFn: () => Promise<{ appLoader: ResourceLoader<ApplicationInjector>, node: JSX.Element; }>,
    setupHotReload?: (acceptFn: () => void) => void,
) {
    const appLoader = new ResourceLoader(loaderFn);
    let forceUpdate: () => void = () => undefined;
    // Accept hot-reload updates for application root
    if (module && module.hot && setupHotReload) {
        setupHotReload(() => {
            const oldInjector = appLoader.value?.appLoader.value;
            // Invalidate loader
            appLoader.invalidate();
            // force-update the app component once new app is loaded
            appLoader.getAsync().then(() => {
                forceUpdate();
                if (oldInjector) {
                    // Dispose old application
                    getLogger('lifecycle').warn('Disposing old application because of hot reload', { debugData: { injector: oldInjector } });
                    oldInjector.dispose();
                }
            });
        });
    }
    return () => {
        const forceUpdateFn = React.useState(0)[1];
        forceUpdate = React.useCallback(() => forceUpdateFn(x => x + 1), []);
        return appLoader.suspenseValue.node;
    };
}
