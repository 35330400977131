// @description CSS Flexbox utility component for responsive designs

import { styled } from '@mui/material/styles';
import isPropValid from '@emotion/is-prop-valid';
import type { Property } from 'csstype';
import type { CSSObject } from '@emotion/core';

import { getResponsiveStyles, ResponsiveValue } from './breakpointUtils';

export { default as FlexItem } from './FlexItem';
export type { FlexItemProps } from './FlexItem';

export interface FlexBaseProps {
    alignContent?: ResponsiveValue<Property.AlignContent>;
    alignItems?: ResponsiveValue<Property.AlignItems>;
    direction: ResponsiveValue<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
    flex?: boolean | number;
    gap?: ResponsiveValue<number | [number, number]>;
    inline?: boolean;
    justifyContent?: ResponsiveValue<Property.JustifyContent>;
    spacing?: ResponsiveValue<number | [number, number]>;
    wrap?: ResponsiveValue<boolean | 'reverse'>;
}

const isFlexPropMap: Record<keyof FlexBaseProps, true> = {
    alignContent: true,
    alignItems: true,
    direction: true,
    flex: true,
    gap: true,
    inline: true,
    justifyContent: true,
    spacing: true,
    wrap: true,
};

/* eslint-disable sonarjs/cognitive-complexity */
const Flex = styled('div', {
    name: 'Flex',
    shouldForwardProp: (p: string) => !isFlexPropMap[p as keyof FlexBaseProps] && isPropValid(p),
})<FlexBaseProps>((props) => {
    const {
        alignContent,
        alignItems,
        direction,
        flex,
        gap,
        inline,
        justifyContent,
        spacing,
        theme,
        wrap,
    } = props;
    const responsiveProps = {
        alignContent,
        alignItems,
        direction,
        flex,
        gap,
        justifyContent,
        spacing,
        wrap,
    };
    const responsiveStyles = getResponsiveStyles(theme, responsiveProps, p => {
        const rProps: CSSObject = {};
        if (p.direction) {
            rProps.flexDirection = p.direction;
        }
        const gap = Array.isArray(p.gap) ? p.gap :
            typeof p.gap === 'number' ? [p.gap + 'px', p.gap + 'px'] :
                Array.isArray(p.spacing) ? p.spacing.map(s => theme.spacing(s)) :
                    typeof p.spacing === 'number' ? [theme.spacing(p.spacing), theme.spacing(p.spacing)] :
                        undefined;
        if (gap) {
            rProps.gap = `${gap[0]} ${gap[1]}`;
            rProps['--hGap'] = gap[0];
            rProps['--vGap'] = gap[1];
        }
        // if (p.spacing) {
        //     if (Array.isArray(p.spacing)) {
        //         rProps.gap = `${theme.spacing(p.spacing[0])} ${theme.spacing(p.spacing[1])}`;
        //     } else {
        //         rProps.gap = theme.spacing(p.spacing);
        //     }
        // }
        // if (p.gap) {
        //     if (Array.isArray(p.gap)) {
        //         rProps.gap = `${p.gap[0]}px ${p.gap[1]}px`;
        //     } else {
        //         rProps.gap = p.gap;
        //     }
        // }
        if (p.wrap) {
            rProps.flexWrap = p.wrap === 'reverse' ? 'wrap-reverse' :
                p.wrap ? 'wrap' :
                    'nowrap';
        }
        if (p.alignItems) {
            rProps.alignItems = p.alignItems;
        }
        if (p.alignContent) {
            rProps.alignContent = p.alignContent;
        }
        if (p.justifyContent) {
            rProps.justifyContent = p.justifyContent;
        }
        if (p.flex) {
            rProps.flex = p.flex === true ? 1 : p.flex;
        }
        return rProps;
    });
    return {
        display: inline ? 'inline-flex' : 'flex',
        boxSizing: 'border-box',
        ...responsiveStyles,
    };
});

export default Flex;

// const testSimple = (
//     <Flex
//         direction='row'
//         flex={6}
//         wrap
//     />
// );

// const testBreakpoints = (
//     <Flex
//         direction={{ xs: 'column', md: 'row' }}
//         flex={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
//         wrap={{ xs: true, md: false }}
//     />
// );
