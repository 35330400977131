// @description Provider component for MUI theming

import React from 'react';
import { pickBy } from 'lodash';
import tinycolor from 'tinycolor2';
import { action, computed, observable, reaction } from 'mobx';
import { GlobalStyles } from '@mui/material';
import { ThemeProvider, createTheme as createMuiTheme, ThemeOptions, Theme as MuiTheme, alpha } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';

import StyledEngineProvider from '@dirico/material/material-ui/StyledEngineProvider';
import namedObserver from '@dirico/components/namedObserver';

import { extendDebugVar } from '@dirico/application-core/utils/debugUtils';

import { pxToRem } from '@dirico/frontend-core/material-ui/muiThemeUtils';

import themeOverrides from './themeOverrides';

export { themeColorTransition } from './themeOverrides';

declare module '@emotion/react' {
    export interface Theme extends MuiTheme { }
}

interface NavBarPalette {
    background: string;
    text: string;
    highlight: string;
}

interface EntityStatusPalette {
    draft: string;
    open: string;
    inProduction: string;
    pending: string;
    done: string;
    backlog: string;
    rejected: string;
    approvedForCommunication: string;
    scheduled: string;
    waitingOnApproval: string;
    approved: string;
    scheduledOnFacebook: string;
    published: string;
}

declare module '@mui/material/styles/createPalette' {
    interface SimplePaletteColorOptions {
        background?: string;
        transparentBackground?: string;
    }

    interface PaletteOptions {
        navBar?: Partial<NavBarPalette>;
        functionalBlue?: PaletteColorOptions;
        entityStatus?: EntityStatusPalette;
    }

    interface Palette {
        navBar: NavBarPalette;
        functionalBlue: PaletteColor;
        entityStatus: EntityStatusPalette;
    }

    interface PaletteColor {
        background?: string;
        transparentBackground?: string;
    }

    interface TypeText {
        inverse: string;
    }
}

interface IThemeCustomizationOptionsBase {
    primaryLight?: string;
    primaryMain?: string;
    primaryDark?: string;
    navBarBackground?: string;
    navBarText?: string;
    navBarHighlight?: string;
}

interface IThemeCustomizationOptions extends IThemeCustomizationOptionsBase {
    primaryMain: string;
    darkModeOptions?: IThemeCustomizationOptionsBase;
}

const defaultThemeCustomizationOptions: IThemeCustomizationOptions = {
    primaryLight: '#569AE8',
    primaryMain: '#147EE2',
    primaryDark: '#005593',
    navBarBackground: '#FFFFFF',
    navBarText: '#00396F',
    navBarHighlight: '#F6DD78',
    darkModeOptions: {
        navBarText: '#569AE8',
        navBarBackground: '#2E2E2E',
        navBarHighlight: 'hsl(48, 88%, 28%)',
    }
};

function createThemeOptions(options: IThemeCustomizationOptions, dark?: boolean): ThemeOptions {
    const light = !dark;
    options = dark ? { ...options, ...options.darkModeOptions } : options;
    return {
        typography: {
            fontFamily: "'Open Sans', sans-serif",
            h1: {
                fontSize: pxToRem(40), lineHeight: pxToRem(50), fontWeight: 300
            },
            h2: {
                fontSize: pxToRem(32), lineHeight: pxToRem(40), fontWeight: 400
            },
            h3: {
                fontSize: pxToRem(28), lineHeight: pxToRem(36), fontWeight: 400
            },
            h4: {
                fontSize: pxToRem(20), lineHeight: pxToRem(28), fontWeight: 600
            },
            h5: {
                fontSize: pxToRem(16), lineHeight: pxToRem(22), fontWeight: 600
            },
            h6: {
                fontSize: pxToRem(14), lineHeight: pxToRem(18), fontWeight: 600
            },
            subtitle1: {
                fontSize: pxToRem(16), lineHeight: pxToRem(20), fontWeight: 400
            },
            subtitle2: {
                fontSize: pxToRem(14), lineHeight: pxToRem(18), fontWeight: 600
            },
            body1: {
                fontSize: pxToRem(16), lineHeight: pxToRem(20), fontWeight: 400
            },
            body2: {
                fontSize: pxToRem(14), lineHeight: pxToRem(18), fontWeight: 400
            },
            caption: {
                fontSize: pxToRem(12), lineHeight: pxToRem(16), fontWeight: 400
            },
            button: {
                fontSize: pxToRem(14),
                lineHeight: pxToRem(18),
                fontWeight: 600,
                textTransform: undefined,
            },
            overline: {
                fontSize: pxToRem(14),
                lineHeight: pxToRem(18),
                fontWeight: 400,
                textTransform: undefined,
            },
        },
        palette: {
            mode: light ? 'light' : 'dark',
            tonalOffset: {
                light: 0.35, // 0.2
                dark: 0.35, // 0.3
            },
            background: {
                // TODO-3: Dark background palette
                default: light ? '#F7F8FA' : '#1F1F1F', // mui default: #303030
                paper: light ? '#FFFFFF' : '#2E2E2E', // mui default: #424242
                dark: alpha('#000000', 0.75),
            } as any,
            text: light ? {
                primary: '#333333',
                secondary: '#666666',
                disabled: '#999999',
                inverse: '#FFFFFF',
            } : {
                // TODO-3: Dark text palette
                // primary: undefined, // #fff
                // secondary: undefined, // rgba(255, 255, 255, 0.7)
                disabled: '#a3a3a3',
                inverse: '#000000',
            },
            // Filter out undefined keys or automatic color generation will not work
            primary: pickBy({
                light: options.primaryLight,
                main: options.primaryMain || defaultThemeCustomizationOptions.primaryMain,
                dark: options.primaryDark,
                background: tinycolor.mix(light ? '#FFFFFF' : '#000000', tinycolor(options.primaryMain || defaultThemeCustomizationOptions.primaryMain), 10).toHexString(),
                transparentBackground: alpha(options.primaryMain, 0.08),
            }, v => Boolean(v)),
            secondary: {
                light: '#F5E6AA',
                main: '#F6DD78',
                dark: '#D3B21A',
                background: light ? '#FFFAE5' : '#484123',
                transparentBackground: alpha('#D3B21A', 0.1),
            },
            functionalBlue: {
                light: '#41639E',
                main: '#00396F',
                dark: '#001443',
                contrastText: '#FFFFFF',
                background: light ? '#E5EFFF' : '#2B333B',
                transparentBackground: alpha('#00396F', 0.08),
            },
            navBar: {
                background: options.navBarBackground || defaultThemeCustomizationOptions.navBarBackground,
                text: options.navBarText || defaultThemeCustomizationOptions.navBarText,
                highlight: options.navBarHighlight || defaultThemeCustomizationOptions.navBarHighlight,
            },
            grey: {
                // Required overrides to make grey-variant button work correctly
                main: grey[300],
                dark: grey[400],
            } as any,
            // action: {
            //     active: light ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.54)',
            // },
            success: {
                light: '#72E6AD',
                main: '#30BF78',
                dark: '#008E4B',
                contrastText: '#FFFFFF',
                background: light ? '#EDF8F2' : '#2B4036',
                transparentBackground: alpha('#30BF78', 0.1),
            },
            info: {
                light: '#569AE8',
                main: '#147EE2',
                dark: '#005593',
                contrastText: '#FFFFFF',
                background: light ? '#E7F3FF' : '#2B333B',
                transparentBackground: alpha('#3A81C4', 0.08),
            },
            warning: {
                light: '#FCCD6F',
                main: '#FAAD14',
                dark: '#C7860C',
                contrastText: '#FFFFFF',
                background: light ? '#FFF6E4' : '#4A3E21',
                transparentBackground: alpha('#FAAD14', 0.1),
            },
            error: {
                light: '#FF5C52',
                main: '#DA1E28',
                dark: '#AF1515',
                contrastText: '#FFFFFF',
                background: light ? '#FBEAEB' : '#3E282A',
                transparentBackground: alpha('#E03F48', 0.08), // warning! base color different from main!
            },
            entityStatus: {
                open: '#14A0C1',
                inProduction: '#722ED1',
                pending: '#FAAD14',
                done: '#008E4B',
                draft: '#AF6E48',
                backlog: '#F6DD78',
                rejected: '#DA1E28',
                approvedForCommunication: '#008E4B',
                scheduled: '#F6DD78',
                waitingOnApproval: '#30BF78',
                approved: '#C160DE',
                scheduledOnFacebook: '#41639E',
                published: '#008E4B',
            },
        },
    };
}

/** Creates a new theme */
export function createTheme(themeOptions: ThemeOptions) {
    const tempTheme = createMuiTheme(themeOptions);
    return createMuiTheme(tempTheme, themeOverrides(tempTheme));
}

const defaultTheme = createTheme(createThemeOptions(defaultThemeCustomizationOptions));

// function calculateTones(main: string) {
//     return {
//         light: tinycolor(main).saturate(10).lighten(20).toHexString(),
//         main: main,
//         dark: tinycolor(main).darken(18).saturate(30).toHexString(),
//     };
// }

/*
Test theming in the browser by executing this in the console:

_debug.themeStore.setPrimary('#CC0000');
_debug.themeStore.setNavbar({
    navBarBackground: '#008800',
    navBarText: '#c00',
    navBarHighlight: '#e88',
});
*/

/** */
export class ThemeStore {

    @observable
    public dark = false;

    @observable
    public options: IThemeCustomizationOptions = defaultThemeCustomizationOptions;

    constructor() {
        try {
            const themeData = JSON.parse(localStorage.getItem('theme-data') || '{}');
            // TODO-3: Enable once application bootstrap takes care of setting theme options based on customer?
            // Object.assign(this.options, themeData.options);
            this.dark = themeData.dark;
        } catch { /**/ }
        reaction(
            () => JSON.stringify({
                dark: this.dark,
                options: this.options,
            }),
            data => {
                try {
                    localStorage.setItem('theme-data', data);
                } catch { /**/ }
            }
        );
    }

    @computed
    public get themeOptions(): ThemeOptions {
        return createThemeOptions(this.options, this.dark);
    }

    @computed
    private get _theme() {
        try {
            return createTheme(this.themeOptions);
        } catch {
            return undefined;
        }
    }

    public get themeHasError() {
        return !this._theme;
    }

    public get theme() {
        return this._theme || defaultTheme;
    }

    @action
    public reset() {
        this.options = defaultThemeCustomizationOptions;
    }

    @action
    public setPrimary(primary?: string) {
        this.options.primaryMain = primary || defaultThemeCustomizationOptions.primaryMain;
        this.options.primaryLight = undefined;
        this.options.primaryDark = undefined;
    }

    @action
    public setNavbar(options: Pick<IThemeCustomizationOptions, 'navBarBackground' | 'navBarHighlight' | 'navBarText'>) {
        this.options.navBarBackground = options.navBarBackground;
        this.options.navBarHighlight = options.navBarHighlight;
        this.options.navBarText = options.navBarText;
    }

    @action.bound
    public toggleDarkMode(): void {
        this.dark = !this.dark;
    }
}

export const themeStore = new ThemeStore();
extendDebugVar({ themeStore });

class ViewportStore {

    @observable
    public mobileMode = true;

    constructor() {
        window.addEventListener('resize', () => this.updateViewport());
        reaction(
            () => this.mobileMode,
            () => this.updateViewport()
        );
    }

    private updateViewport() {
        const metaEl = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
        metaEl.content = !this.mobileMode && window.screen.width < 598 ? 'width=598' : 'width=device-width, initial-scale=1';
    }
}

export const viewportStore = new ViewportStore();

const AppThemeProvider = namedObserver('AppThemeProvider', (props: { children: React.ReactNode; }) => {
    React.useEffect(() => {
        window.document.body.style.setProperty('--theme-transition-duration', '200ms');
    });
    return (
        <StyledEngineProvider>
            <ThemeProvider theme={themeStore.theme}>
                <GlobalStyles styles={theme => ({
                    /* This fixes a weird chrome bug where sometimes color on links would sometimes not be inherited correctly  */
                    ':where(a:any-link > *)': {
                        color: 'inherit',
                        textDecoration: 'inherit',
                    },
                    '.os-theme-dark': {
                        '& > .os-scrollbar': {
                            '& > .os-scrollbar-track > .os-scrollbar-handle': {
                                background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)',
                            },
                            '&:hover > .os-scrollbar-track>.os-scrollbar-handle': {
                                background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.38)' : 'rgba(255, 255, 255, 0.38)',
                            },
                            '& > .os-scrollbar-track > .os-scrollbar-handle.active': {
                                background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
                            },
                        },
                    },
                })} />
                {props.children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
});

export default AppThemeProvider;


