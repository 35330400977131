// @description Fix for Firefox bug with performance.mark() and performance.measure() not returning any value
// see https://bugzilla.mozilla.org/show_bug.cgi?id=1724645
// TODO-3: Has been fixed in Firefox on 2022-04-27 - can be removed soon

if (typeof window !== 'undefined' && typeof window.performance?.mark !== 'undefined' && window.performance.mark('$test-for-polyfill') === undefined) {
    const markMap = new Map<string, PerformanceEntry>();

    const performanceMark = window.performance.mark;
    window.performance.mark = (name, markOptions) => {
        const startTime = window.performance.now();
        performanceMark.call(window.performance, name, markOptions);
        const mark: PerformanceMark = {
            entryType: 'mark',
            name,
            startTime,
            duration: 0,
            detail: null,
            toJSON: undefined as any,
        };
        markMap.set(name, mark);
        return mark;
    };

    const performanceMeasure = window.performance.measure;
    window.performance.measure = (name, startOrMeasureOptions, endMark) => {
        const now = window.performance.now();
        performanceMeasure.call(window.performance, name, startOrMeasureOptions, endMark);

        const measureOptions: PerformanceMeasureOptions = typeof startOrMeasureOptions === 'object' ? startOrMeasureOptions : {
            start: startOrMeasureOptions,
            end: endMark,
        };
        const startTime = (typeof measureOptions.start === 'string' ?
            markMap.get(measureOptions.start)?.startTime :
            measureOptions.start
        ) || window.performance.getEntriesByType('navigation')[0]?.startTime || 0;
        const endTime = (typeof measureOptions.end === 'string' ?
            markMap.get(measureOptions.end)?.startTime :
            measureOptions.end
        ) || now;
        const duration = endTime - startTime;

        const measure: PerformanceMeasure = {
            entryType: 'measure',
            name,
            duration,
            startTime,
            detail: null,
            toJSON: undefined as any,
        };
        return measure;
    };
}

export { };
