// @description Wrapper around mui Stack component to give it more semantic meaning

import React from 'react';
import type { Property } from 'csstype';
// import { Stack, StackProps } from '@mui/material';

import { joinChildren } from '@dirico/components/reactUtils';

import Flex, { FlexBaseProps } from './Flex';

export { default as FlexItem } from './FlexItem';
export type { FlexItemProps } from './FlexItem';

type BasePlacements = 'space-between' | 'space-around' | 'space-evenly';
type VerticalPlacements = 'top' | 'middle' | 'bottom';
type HorizontalPlacements = 'left' | 'center' | 'right';

export type HBoxVerticalAlign = VerticalPlacements | 'stretch';
export type HBoxVerticalPosition = VerticalPlacements | BasePlacements;
export type HBoxHorizontalPosition = HorizontalPlacements | BasePlacements;

export type VBoxHorizontalAlign = HorizontalPlacements | 'stretch';
export type VBoxHorizontalPosition = HorizontalPlacements | BasePlacements;
export type VBoxVerticalPosition = VerticalPlacements | BasePlacements;

type ContentDistribution = 'space-around' | 'space-between' | 'space-evenly' | 'stretch';
type ContentPosition = 'center' | 'end' | 'flex-end' | 'flex-start' | 'start';

const BasePlacementAttributes: Record<BasePlacements, ContentDistribution> = {
    'space-around': 'space-around',
    'space-between': 'space-between',
    'space-evenly': 'space-evenly',
};

const HorizontalPlacementAttributes: Record<HorizontalPlacements, ContentPosition> = {
    'left': 'flex-start',
    'center': 'center',
    'right': 'flex-end',
};

const VerticalPlacementAttributes: Record<VerticalPlacements, ContentPosition> = {
    'top': 'flex-start',
    'middle': 'center',
    'bottom': 'flex-end',
};

const HBoxVerticalAligns: Record<HBoxVerticalAlign, Property.AlignItems> = {
    ...VerticalPlacementAttributes,
    'stretch': 'stretch',
};

const HBoxVerticalPositions: Record<HBoxVerticalPosition, Property.AlignContent> = {
    ...BasePlacementAttributes,
    ...VerticalPlacementAttributes,
};

const HBoxHorizontalPositions: Record<HBoxHorizontalPosition, Property.JustifyContent> = {
    ...BasePlacementAttributes,
    ...HorizontalPlacementAttributes,
};

const VBoxHorizontalAligns: Record<VBoxHorizontalAlign, Property.AlignItems> = {
    ...HorizontalPlacementAttributes,
    'stretch': 'stretch',
};

const VBoxHorizontalPositions: Record<VBoxHorizontalPosition, Property.AlignContent> = {
    ...BasePlacementAttributes,
    ...HorizontalPlacementAttributes,
};

const VBoxVerticalPositions: Record<VBoxVerticalPosition, Property.JustifyContent> = {
    ...BasePlacementAttributes,
    ...VerticalPlacementAttributes,
};

type FlexOmittedProps = 'direction' | 'alignItems' | 'alignContent' | 'justifyContent';

export interface HStackBaseProps extends Omit<FlexBaseProps, FlexOmittedProps> {
    divider?: React.ReactElement;
    verticalAlign?: HBoxVerticalAlign;
    verticalPosition?: HBoxVerticalPosition;
    horizontalPosition?: HBoxHorizontalPosition;
}

export type HStackProps = HStackBaseProps & Omit<React.ComponentProps<typeof Flex>, FlexOmittedProps>;
// export type HStackProps = HStackBaseProps & React.HTMLAttributes<HTMLDivElement>;

export const HStack = React.forwardRef((props: HStackProps, ref: React.Ref<HTMLDivElement>): React.ReactElement => {
    const {
        children,
        divider,
        verticalAlign,
        verticalPosition,
        horizontalPosition,
        ...rest
    } = props;
    return (
        <Flex
            ref={ref}
            direction='row'
            alignItems={verticalAlign ? HBoxVerticalAligns[verticalAlign] : undefined}
            alignContent={verticalPosition ? HBoxVerticalPositions[verticalPosition] : undefined}
            justifyContent={horizontalPosition ? HBoxHorizontalPositions[horizontalPosition] : undefined}
            {...rest}
        >
            {divider ? joinChildren(children, divider) : children}
        </Flex>
    );
});
HStack.displayName = 'HStack';

export interface VStackBaseProps extends Omit<FlexBaseProps, FlexOmittedProps> {
    divider?: React.ReactElement;
    horizontalAlign?: VBoxHorizontalAlign;
    horizontalPosition?: VBoxHorizontalPosition;
    verticalPosition?: VBoxVerticalPosition;
}

export type VStackProps = VStackBaseProps & Omit<React.ComponentProps<typeof Flex>, FlexOmittedProps>;
// export type VStackProps = VStackBaseProps & React.HTMLAttributes<HTMLDivElement>;

export const VStack = React.forwardRef((props: VStackProps, ref: React.Ref<HTMLDivElement>): React.ReactElement => {
    const {
        children,
        horizontalAlign,
        horizontalPosition,
        verticalPosition,
        divider,
        ...rest
    } = props;
    return (
        <Flex
            ref={ref}
            direction='column'
            alignItems={horizontalAlign ? VBoxHorizontalAligns[horizontalAlign] : undefined}
            alignContent={horizontalPosition ? VBoxHorizontalPositions[horizontalPosition] : undefined}
            justifyContent={verticalPosition ? VBoxVerticalPositions[verticalPosition] : undefined}
            {...rest}
        >
            {divider ? joinChildren(children, divider) : children}
        </Flex>
    );
});
VStack.displayName = 'VStack';
