import React from 'react';

declare module 'csstype' {
    interface Properties {
        // Allow any CSS Custom Properties
        [index: `--${string}`]: any;
    }
}

/**
 * Return an array with the separator React element interspersed between
 * each React node of the input children.
 *
 * > joinChildren([1,2,3], 0)
 * [1,0,2,0,3]
 */
export function joinChildren(children: React.ReactNode, separator: React.ReactElement) {
    const childrenArray = React.Children.toArray(children).filter(Boolean);
    const result: React.ReactNode[] = [];
    for (let index = 0; index < childrenArray.length; index++) {
        result.push(childrenArray[index]);
        if (index < childrenArray.length - 1) {
            result.push(React.cloneElement(separator, { key: `separator-${index}` }));
        }
    }
    return result;
}

export const stopPropagationHandler = (e: React.SyntheticEvent) => e.stopPropagation();

/** If a react node is just a single ReactFragment, return its children instead. Otherwise returns the passed element */
export function unwrapReactFragment(node?: React.ReactNode): React.ReactNode {
    return React.isValidElement(node) && node.type === React.Fragment ? node.props.children : node;
}

// TODO-2: Add tests for unwrapReactFragments

/**
 * Helper function to unwrap an array of React.Fragment elements.
 * This can be useful for providing content to a MUI Menu component
 * 
 * @example
 * unwrapReactFragments(groups.map(([key, items]) => (
 *   <React.Fragment key={key}>
 *     <h4>{group}</h4>
 *     {items.map(item => (
 *       <li key={item.id}>
 *         {item.title}
 *       </li>
 *     ))}
 *   </React.Fragment>
 * )))
 */
export function unwrapReactFragments(element: React.ReactNode): React.ReactNode[] {
    if (!React.isValidElement(element) || element.type !== React.Fragment) {
        return [element];
    }
    const result: React.ReactNode[] = [];
    function handleChildren(children: React.ReactNode, baseKey: React.Key | null) {
        React.Children.map(children, child => {
            if (!React.isValidElement<{ children?: React.ReactNode; }>(child)) {
                result.push(child);
            } else if (child.type !== React.Fragment) {
                result.push(!baseKey ? child : React.cloneElement(child, { ...child.props, key: baseKey + '_' + child.key }));
            } else {
                handleChildren(child.props.children, baseKey ? baseKey + '_' + child.key : child.key);
            }
        });
    }
    handleChildren(element.props.children, element.key);
    return result;
}

/** Returns an array of only React.ReactElement items from the input children prop */
export function elementChildren<T = any>(children?: React.ReactNode) {
    return React.Children.toArray(children).filter(x => React.isValidElement(x)) as React.ReactElement<T>[];
}

/**
 * Returns true, if the bubbled event should trigger a form submit where appropriate
 */
export function isFormSubmitEvent(event: React.KeyboardEvent<any>) {
    if (event.key !== 'Enter') {
        return;
    }
    let activeElement = window.document.activeElement as HTMLElement;
    if (activeElement) {
        if (activeElement.tagName === 'TEXTAREA' || activeElement.tagName === 'BUTTON' || activeElement.tagName === 'A') {
            return;
        }
        while (activeElement && activeElement !== document.body) {
            if (activeElement.isContentEditable) {
                return;
            }
            activeElement = activeElement.parentElement as HTMLElement;
        }
    }
    return true;
}

/**
 * Type guard function to check if the passed variable is a React.RefObject (object with `current` property)
 */
export function isRefObject<T = unknown>(x: any): x is React.RefObject<T> {
    return x && typeof x === 'object' && Object.hasOwnProperty.call(x, 'current');
}
