// @description Utilities to create mui themes

import {
    createTheme as createMuiTheme,
    PaletteColorOptions,
    SimplePaletteColorOptions,
    Theme,
    ThemeOptions,
} from '@mui/material/styles';

/** Calculates rem value for a certain target and body font size  */
export function pxToRem(size: number, fontSizePx = 14) {
    const htmlFontSize = 16; // px
    const coefficient = fontSizePx / 14;
    return `${(size / htmlFontSize) * coefficient}rem`;
}

/** Checks, if the palette options are of SimplePaletteColorOptions type */
export function isSimplePaletteColorOptions(opt: PaletteColorOptions): opt is SimplePaletteColorOptions {
    return Boolean((opt as any).main);
}

/** */
export function createThemeWithOverrides(options: ThemeOptions, defaultOverrides: (buildTheme: Theme) => ThemeOptions, overrides?: (theme: Theme) => ThemeOptions) {
    const baseTheme = createMuiTheme(options);
    return overrides ?
        createMuiTheme(baseTheme, defaultOverrides(baseTheme), overrides(baseTheme)) :
        createMuiTheme(baseTheme, defaultOverrides(baseTheme));
}
