import type React from 'react';
import { observer } from 'mobx-react-lite';

import withDisplayName from './withDisplayName';

export default function namedObserver<P extends object>(baseComponent: React.VoidFunctionComponent<P>): React.VoidFunctionComponent<P>;
export default function namedObserver<P extends object>(name: string, baseComponent: React.VoidFunctionComponent<P>): React.VoidFunctionComponent<P>;
/**
 * Same as mobx.observer, but assigns the function a name before doing so.
 * This allows using lambdas without loosing display names.
 * Also names will be minification-safe.
 */
export default function namedObserver<P extends object>(nameOrComponent: string | React.FunctionComponent<P>, baseComponent?: React.FunctionComponent<P>) {
    if (typeof nameOrComponent !== 'string') {
        return observer(nameOrComponent);
    } else {
        return withDisplayName(nameOrComponent, observer(baseComponent!));
    }
}

export function namedObserverWithRef<P extends object, TRef = {}>(baseComponent: React.ForwardRefRenderFunction<TRef, P>):
    React.MemoExoticComponent<React.ForwardRefExoticComponent<P & React.RefAttributes<TRef>>>;
export function namedObserverWithRef<P extends object, TRef = {}>(name: string, baseComponent: React.ForwardRefRenderFunction<TRef, P>):
    React.MemoExoticComponent<React.ForwardRefExoticComponent<P & React.RefAttributes<TRef>>>;
/**
 * Same as mobx.observer, but assigns the function a name before doing so.
 * This allows using lambdas without loosing display names.
 * Also names will be minification-safe.
 */
export function namedObserverWithRef<P extends object, TRef = {}>(
    nameOrComponent: string | React.ForwardRefRenderFunction<TRef, P>,
    baseComponent?: React.ForwardRefRenderFunction<TRef, P>
) {
    if (typeof nameOrComponent !== 'string') {
        return observer(nameOrComponent, { forwardRef: true });
    } else {
        return withDisplayName(nameOrComponent, observer(baseComponent!, { forwardRef: true }));
    }
}
