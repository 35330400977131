import type React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { Breakpoint, styled } from '@mui/material/styles';
import type { GridSize } from '@mui/material';
import type { CSSObject } from '@emotion/core';

import { objectEntries } from '@dirico/utils/ts-utils';

export type BreakpointGridSizes = { [k in Breakpoint]?: boolean | GridSize; };

export interface FlexItemBaseProps extends BreakpointGridSizes {
    /**
     * If set, the item will try to use the width specified through `xs` & co. or flex-basis,
     * but can still grow larger to take up remaining space in wrapping layouts
     */
    grow?: boolean;
    flexBasis?: string | number;
}

function flexItemBreakpoint(value: boolean | GridSize | undefined, minWidth: number = 0, grow?: boolean) {
    if (value === undefined || value === false) {
        return undefined;
    }
    const styles = value === true ? {
        flexGrow: 1,
        maxWidth: '100%',
        flexBasis: 0,
    } : typeof value === 'number' ? (
        grow ? {
            flexGrow: 1,
            minWidth: 0,
            flexBasis: `calc((100% - var(--hGap, 0px)) * ${value / 12})`,
        } : {
            flexGrow: 0,
            maxWidth: `${100 / 12 * value}%`,
            flexBasis: `${100 / 12 * value}%`,
        }
    ) : {};
    return minWidth <= 0 ? styles : { [`@media (min-width: ${minWidth}px)`]: styles };
}

const FlexItem = styled('div', {
    name: 'FlexItem',
    shouldForwardProp: isPropValid,
})<FlexItemBaseProps>((props) => {
    const result: CSSObject = Object.assign(
        {
            label: 'FlexItem',
            maxWidth: '100%'
        },
        props.flexBasis && { flexBasis: props.flexBasis },
        props.grow && { flexGrow: 1 },
        ...objectEntries(props.theme.breakpoints.values).map(([key, minWidth]) => flexItemBreakpoint(props[key], minWidth, props.grow)),
    );
    return result;
});
FlexItem.displayName = 'FlexItem';

export default FlexItem;

export type FlexItemProps = React.ComponentProps<typeof FlexItem>;
