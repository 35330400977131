// @description Utilities for debugging an application which will be appended to window._debug on load

import { configure, observable, reaction, runInAction, toJS, autorun, trace, getDependencyTree, getDebugName, getObserverTree, getAtom } from 'mobx';

import * as Tokens from '@dirico/application-core/tokens';
import type ApplicationInjector from '@dirico/application-core/services/ApplicationInjector';


declare global {
    // eslint-disable-next-line no-var
    var _debug: unknown;
}

function getMobxStrictMode() {
    const obs = observable({ v: 0 });
    const r = reaction(() => obs.v, () => undefined);
    try {
        obs.v = 1; // Try changing an observable - if it throws, the current mode is "observed"
        return false;
    } catch {
        return true;
    } finally {
        r();
    }
}

function toggleMobxStrict(enabled: boolean = !getMobxStrictMode()) {
    configure({ enforceActions: enabled ? 'observed' : 'never' });
}

/** Extends globalThis._debug object with the provided utils */
export function extendDebugVar(utils: Record<string, any>) {
    const debugObj = globalThis._debug || (globalThis._debug = {});
    Object.defineProperties(debugObj, Object.getOwnPropertyDescriptors(utils));
    // Object.assign(debugObj, utils);
}

/** Creates some debugging utilities to be added to globalThis._debug */
export async function createCoreDebugUtils(injector: ApplicationInjector) {
    const translator = await injector.get(Tokens.TranslatorToken);
    const viewManager = await injector.get(Tokens.ViewManagerToken);
    const modalService = await injector.get(Tokens.ModalsServiceToken);
    return {
        injector,
        translator,
        get view() { return viewManager.view; },
        deps: injector.deps,
        modalService,
        get topModal() { return modalService.stackTop; },
    } as const;
}

extendDebugVar({
    mobx: {
        /** runInAction from mobx package */
        runInAction,
        toJS,
        autorun,
        trace,
        getDependencyTree,
        getDebugName,
        getObserverTree,
        getAtom,
        /** Toggles mobx enforceActions option between 'observed' (strict) and 'never' */
        toggleStrictMode: toggleMobxStrict,
    },
});
