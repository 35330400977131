// @description Main entry point which initialized authentication and renders the AppLoader (loading spinner)

import '@dirico/utils/Logger';

import React from 'react';
import { createRoot } from 'react-dom/client';

import AppLoader from './AppLoader';

const rootElement = document.getElementById('root')!;

const root = createRoot(rootElement);
root.render(<AppLoader />);

if ('serviceWorker' in navigator) {
    // Use the window load event to keep the page load performant
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js');
    });
}
