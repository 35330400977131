import React from 'react';

const ShowAfterDelay = (props: { children: React.ReactElement; delay: number; before?: React.ReactElement; }) => {
    const {
        children,
        delay,
        before,
    } = props;
    const [isAfter, setAfter] = React.useState(false);
    React.useEffect(() => {
        const t = setTimeout(() => setAfter(true), delay);
        return () => clearTimeout(t);
    }, []);
    return isAfter ? children : before! || null;
};

export default ShowAfterDelay;
