import type React from 'react';

/**
 * Returns the same component but with a displayName assigned to it
 */
export default function withDisplayName<T extends React.ComponentType<any>>(name: string | undefined | false, component: T): T {
    if (name) {
        const fn = getInnermostFunction(component);
        if (fn) {
            fn.displayName = name;
            Object.defineProperty(fn, 'name', { value: name, enumerable: false });
        } else {
            component.displayName = name;
        }
    }
    return component;
}

type AnyReactComponentType<P = any> = React.ComponentType<P> | React.MemoExoticComponent<React.ComponentType<P>> | React.NamedExoticComponent<P>;

function getInnermostFunction(cmp: AnyReactComponentType): AnyReactComponentType {
    return typeof (cmp as any) === 'function' ? cmp as any :
        isMemoComponent(cmp) ? getInnermostFunction(cmp.type) :
            isForwardRefComponent(cmp) ? getInnermostFunction(cmp.render) :
                undefined;
}

function isMemoComponent<P>(cmp: AnyReactComponentType<P>): cmp is React.MemoExoticComponent<React.ComponentType<P>> {
    return (
        typeof cmp === 'object' &&
        Object.prototype.hasOwnProperty.call(cmp, 'compare') &&
        Object.prototype.hasOwnProperty.call(cmp, 'type')
    );
}

function isForwardRefComponent<P>(cmp: AnyReactComponentType<P>): cmp is React.ForwardRefExoticComponent<P> & { render: any; } {
    return (
        typeof cmp === 'object' &&
        Object.prototype.hasOwnProperty.call(cmp, 'render')
    );
}
