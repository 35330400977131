// @description utilities to build responsive components

import type { Breakpoint, Theme } from '@mui/material';
import type { CSSObject } from '@emotion/core';

import { objectEntries, objectFromEntries, objectKeys, PartialRecord } from '@dirico/utils/ts-utils';

export type ResponsiveValue<T> = T | PartialRecord<Breakpoint, T>;

export type UnwrapResponsiveValues<T> = { [k in keyof T]?: T[k] extends ResponsiveValue<infer V> ? V : never };

export type MapResponsiveProps<T extends Record<keyof any, ResponsiveValue<any>>> = Record<Breakpoint, UnwrapResponsiveValues<T>>;

export type MapResponsivePropsIsDefined<T extends Record<keyof any, ResponsiveValue<any>>> = Record<Breakpoint, Record<keyof T, boolean>>;

/** */
export function populateBreakpointsBase(breakpoints: Breakpoint[], props: Record<keyof any, ResponsiveValue<any>>) {
    const base: PartialRecord<Breakpoint, true> = { [breakpoints[0]]: true };
    for (const prop of Object.values(props)) {
        if (prop && typeof prop === 'object') {
            for (const breakpoint of breakpoints) {
                if ((prop as any)[breakpoint] != null) {
                    base[breakpoint] = true;
                }
            }
        }
    }
    return base;
}

/** */
export function mapResponsiveProps<T extends Record<keyof any, ResponsiveValue<any>>>(base: PartialRecord<Breakpoint, true>, props: T) {
    const breakpoints = objectKeys(base);
    const result: MapResponsiveProps<T> = objectFromEntries(breakpoints.map(brk => [brk, {}])) as any;
    const isDefinedMap: MapResponsivePropsIsDefined<T> = objectFromEntries(breakpoints.map(brk => [brk, {}])) as any;
    for (const [key, value] of objectEntries(props)) {
        if (value && typeof value === 'object' && !Array.isArray(value)) {
            let last: any = undefined;
            for (const breakpoint of breakpoints) {
                if (Object.prototype.hasOwnProperty.call(value, breakpoint)) {
                    last = result[breakpoint][key] = value[breakpoint];
                    isDefinedMap[breakpoint][key] = true;
                } else {
                    result[breakpoint][key] = last;
                }
            }
        } else {
            isDefinedMap[breakpoints[0]][key] = true;
            for (const breakpoint of breakpoints) {
                result[breakpoint][key] = value;
            }
        }
    }
    return { responsiveProps: result, isDefinedMap };
}

/** 
 * Main function to build responsive styles for components.
 * Pass it an object of ResponsiveValue values and it will use `styleFunction` callback to generate proper responsive styles
 */
export function getResponsiveStyles<T extends Record<keyof any, ResponsiveValue<any>>>(
    theme: Theme,
    props: T,
    styleFunction: (resolvedProps: UnwrapResponsiveValues<T>, definedProps: Record<keyof T, boolean>) => CSSObject
) {
    const base = populateBreakpointsBase(theme.breakpoints.keys, props);
    const breakpointProps = mapResponsiveProps(base, props);
    const styles: Record<string, any> = {};
    for (const [breakpoint, p] of objectEntries(breakpointProps.responsiveProps)) {
        const breakpointStyles: CSSObject = styleFunction(p, breakpointProps.isDefinedMap[breakpoint]);
        if (theme.breakpoints.values[breakpoint] <= 0) {
            Object.assign(styles, breakpointStyles);
        } else {
            styles[theme.breakpoints.up(breakpoint)] = breakpointStyles;
        }
    }
    return styles;
}
