// @description Provider component for MUI theming

import React from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';

export const muiCache = createCache({
    key: 'mui',
    prepend: true,
    // We don't need no vendor prefixes as we use evergreen browser features only!
    stylisPlugins: [],
});

export const tssCache = createCache({
    key: 'tss-react',
    // We don't need no vendor prefixes as we use evergreen browser features only!
    stylisPlugins: [],
});

const StyledEngineProvider = React.memo((props: { children: React.ReactNode; }) => {
    return (
        <CacheProvider value={muiCache}>
            <TssCacheProvider value={tssCache}>
                    {props.children}
            </TssCacheProvider>
        </CacheProvider>
    );
});

export default StyledEngineProvider;
