// @description Provider component for MUI theming

import { switchClasses } from '@mui/material/Switch';
import { inputBaseClasses } from '@mui/material/InputBase';
import { Theme, ThemeOptions, alpha } from '@mui/material/styles';

import { pxToRem } from '@dirico/frontend-core/material-ui/muiThemeUtils';

export const themeColorTransition = 'color var(--theme-transition-duration) linear, background-color var(--theme-transition-duration) linear';

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        grey: true;
        transparent: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        functionalBlue: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        functionalBlue: true;
    }
}

const overrides = (theme: Theme): ThemeOptions => {
    const light = theme.palette.mode === 'light';
    return {
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        '--theme-transition-duration': '0ms',
                        // Apply color and background only to main div, so that other features that work on body are not affected (ie. copy to clipboard)
                        backgroundColor: 'initial',
                        color: 'initial',
                        '&>#root': {
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            transition: themeColorTransition,
                        },
                        ...theme.typography.body2,
                    },
                    a: {
                        textDecoration: 'none',
                        color: 'inherit',
                        '&:visited': {
                            color: 'inherit',
                        },
                        // '&:hover': {
                        //     textDecoration: 'underline',
                        // },
                    },
                }
            },
            // Keep all component overrides alphabetically sorted please
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        width: 32,
                        height: 32,
                        backgroundColor: theme.palette.text.disabled,
                        fontSize: '12px',
                        fontWeight: 600,
                    },
                },
            },
            MuiBreadcrumbs: {
                defaultProps: {
                    separator: ' > ',
                },
                styleOverrides: {
                    li: {
                        color: theme.palette.text.secondary,
                        '&:last-child > *': {
                            color: theme.palette.text.primary,
                        },
                        '&:hover > *, &:active > *': {
                            color: theme.palette.text.primary,
                        },
                    },
                    root: {
                        '&:not(.no-bold-last-item) > ol > li:last-child > *': {
                            fontWeight: 600,
                        }
                    },
                    separator: {
                        color: theme.palette.text.secondary,
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                    variant: 'contained',
                },
                variants: [
                    // contained-grey
                    {
                        props: { variant: 'contained', color: 'grey' },
                        style: {
                            color: theme.palette.getContrastText(theme.palette.grey[300])
                        }
                    },
                    // outlined-grey
                    {
                        props: { variant: 'outlined', color: 'grey' },
                        style: {
                            color: theme.palette.text.secondary,
                            borderColor: light ? theme.palette.text.secondary : 'rgba(255, 255, 255, 0.23)',
                            '&.Mui-disabled': {
                                border: `1px solid ${theme.palette.action.disabledBackground}`
                            },
                            '&:hover': {
                                color: light ? theme.palette.text.primary : 'rgba(255, 255, 255, 0.23)',
                                borderColor: light ? theme.palette.text.primary : 'rgba(255, 255, 255, 0.23)',
                                backgroundColor: 'transparent',
                            },
                        }
                    },
                    // text-grey
                    {
                        props: { variant: 'text', color: 'grey' },
                        style: {
                            color: theme.palette.text.primary,
                            '&:hover': {
                                backgroundColor: alpha(
                                    theme.palette.text.primary,
                                    theme.palette.action.hoverOpacity
                                )
                            }
                        }
                    },
                ],
                styleOverrides: {
                    root: {
                        minHeight: 32,
                        padding: '6px 12px', // original mui: 6px 16px
                    },
                    startIcon: {
                        marginLeft: -2,
                    },
                    contained: {
                        '&.Mui-disabled': {
                            color: theme.palette.text.disabled,
                        },
                    },
                    containedSizeSmall: {
                        padding: '1px 10px',
                        fontSize: theme.typography.pxToRem(12),
                    },
                    containedPrimary: {
                        [`&$focusVisible`]: {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    },
                    outlined: {
                        padding: '5px 12px', // original mui: 5px 15px
                        transition: 'color 100ms linear, border-color 100ms linear',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&.Mui-disabled': {
                            backgroundColor: theme.palette.action.hover,
                            color: theme.palette.text.disabled,
                        }
                    },
                    outlinedPrimary: {
                        borderColor: theme.palette.primary.main,
                        transition: 'color 100ms linear, border-color 100ms linear',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: light ? theme.palette.primary.dark : theme.palette.primary.light,
                            borderColor: light ? theme.palette.primary.dark : theme.palette.primary.light,
                        }
                    },
                    outlinedSecondary: {
                        borderColor: theme.palette.secondary.main,
                        transition: 'color 100ms linear, border-color 100ms linear',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: light ? theme.palette.secondary.dark : theme.palette.secondary.light,
                            borderColor: light ? theme.palette.secondary.dark : theme.palette.secondary.light,
                        }
                    },
                    outlinedSizeSmall: {
                        padding: '0px 9px',
                        fontSize: theme.typography.pxToRem(12),
                    },
                    text: {
                        padding: '7px 12px',
                    },
                    textPrimary: {
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                    textSizeSmall: {
                        padding: '1px 5px',
                        fontSize: theme.typography.pxToRem(12),
                    }
                },
            },
            MuiToggleButtonGroup: {
                styleOverrides: {
                    root: {
                        background: theme.palette.background.paper,
                        '.MuiButtonBase-root': {
                            border: 'none',
                            color: theme.palette.info.main
                        },
                        '.MuiButtonBase-root.Mui-selected': {
                            background: theme.palette.info.main,
                            color: theme.palette.common.white,
                            borderRadius: 4,
                            ':hover': {
                                background: theme.palette.info.dark
                            }
                        }
                    }
                }
            },
            MuiButtonGroup: {
                defaultProps: {
                    disableElevation: true,
                    variant: 'contained',
                },
                styleOverrides: {
                    contained: {
                        boxShadow: undefined,
                    },

                    groupedContainedHorizontal: {
                        '&:not(:last-child)': {
                            '&.Mui-disabled': {
                                borderRightColor: 'rgba(255, 255, 255, 0.75)',
                            },
                        },
                    },
                    groupedContainedVertical: {
                        '&:not(:last-child)': {
                            '&.Mui-disabled': {
                                borderBottomColor: 'rgba(255, 255, 255, 0.75)',
                            },
                        },
                    },
                    groupedContainedPrimary: {
                        '&:not(:last-child)': {
                            borderColor: theme.palette.primary.light,
                        },
                    },
                    groupedContainedSecondary: {
                        '&:not(:last-child)': {
                            borderColor: theme.palette.secondary.light,
                        },
                    },
                },
            },
            MuiCheckbox: {
                defaultProps: {
                    size: 'small', // All checkboxes default to small size
                },
                styleOverrides: {
                    root: {
                        color: theme.palette.divider,
                        '&.Mui-disabled': {
                            color: theme.palette.divider,
                        },
                        '&.PrivateSwitchBase-edgeStart': {
                            marginLeft: -9,
                        },
                    },
                    colorPrimary: {
                        '&.Mui-checked': {
                            color: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                                // Reset on touch devices, it doesn't add specificity
                                '@media (hover: none)': {
                                    backgroundColor: 'transparent',
                                },
                            },
                            '&.Mui-disabled': {
                                // Reset transparent disabled color. Instead use opacity
                                opacity: 0.5,
                            },
                        },
                    },
                    colorSecondary: {
                        '&.Mui-checked': {
                            color: theme.palette.secondary.main,
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
                                // Reset on touch devices, it doesn't add specificity
                                '@media (hover: none)': {
                                    backgroundColor: 'transparent',
                                },
                            },
                            '&.Mui-disabled': {
                                // Reset transparent disabled color. Instead use opacity
                                opacity: 0.5,
                            },
                        },
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    avatar: {
                        backgroundColor: 'unset',
                        margin: 2,
                        marginRight: -4,
                    },
                    avatarMedium: {
                        height: 28,
                        width: 28,
                    },
                    avatarSmall: {
                        height: 20,
                        width: 20,
                    },
                    icon: {
                        marginLeft: 12,
                        color: theme.palette.text.primary,
                    },
                    iconSmall: {
                        fontSize: 20,
                    },
                    label: {
                        ...theme.typography.body2,
                    },
                    labelSmall: {
                        paddingLeft: 12,
                        paddingRight: 12,
                    },
                    sizeSmall: {
                        borderRadius: 12,
                    },
                    deleteIcon: {
                        marginLeft: -8,
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: '32px 24px 4px 24px',
                        display: 'flex',
                        alignItems: 'center',
                        ...theme.typography.h5,
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        padding: '20px 24px 18px',
                        '.MuiDialogTitle-root + &': {
                            paddingTop: 20,
                        },
                    },
                    dividers: {
                        borderBottom: 'none'
                    }
                },
            },
            MuiDialogContentText: {
                styleOverrides: {
                    root: {
                        ...theme.typography.body2,
                        color: theme.palette.text.primary,
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        padding: '18px 24px 24px'
                    },
                    spacing: {
                        '& > :not(:first-of-type)': {
                            marginLeft: 20,
                        },
                    }
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: theme.typography.body2,
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        color: '#999999',
                        marginTop: 4,
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    sizeSmall: {
                        padding: 3,
                    }
                },
                variants: [
                    {
                        props: { color: 'functionalBlue' },
                        style: {
                            color: light ? theme.palette.functionalBlue.main : theme.palette.functionalBlue.light,
                        }
                    },
                ]
            },
            MuiInput: {
                defaultProps: {
                    disableUnderline: true,
                },
                styleOverrides: {
                    root: {
                        backgroundColor: light ? '#FFFFFF' : 'transparent',
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: 4,
                        minHeight: 32,
                        '&:hover:not(.Mui-disabled)': {
                            borderColor: light ? '#000000' : '#BBBBBB',
                        },
                        [`&.Mui-focused.Mui-focused`]: {
                            borderColor: theme.palette.primary.main,
                        },
                        [`&.Mui-error`]: {
                            borderColor: theme.palette.error.main,
                        },
                        '&.Mui-disabled': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                    input: {
                        // minHeight: 17,
                        // Number input customization
                        '&[type="number"]': {
                            marginRight: '-12px',
                            width: 'calc(100% + 12px)',
                        },
                        '&::-webkit-inner-spin-button': {
                            opacity: 1,
                            pointerEvents: 'auto',
                            height: 30,
                        },
                        '&::-moz-number-spin-box': {
                            height: '30px',
                        },
                        // Show and hide the placeholder logic
                        [`label[data-shrink=false] + .${inputBaseClasses.formControl} &`]: {
                            '&::-webkit-input-placeholder': { opacity: '1 !important', color: `${theme.palette.text.disabled} !important` },
                            '&::-moz-placeholder': { opacity: '1 !important', color: `${theme.palette.text.disabled} !important` }, // Firefox 19+
                            '&:-ms-input-placeholder': { opacity: '1 !important', color: `${theme.palette.text.disabled} !important` }, // IE 11
                            '&::-ms-input-placeholder': { opacity: '1 !important', color: `${theme.palette.text.disabled} !important` }, // Edge
                        },
                    },
                    formControl: {
                        'label + &': {
                            marginTop: 20,
                        }
                    }
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    root: {
                        // height: undefined,
                    },
                    positionStart: {
                        marginLeft: 12,
                        marginRight: -4,
                    },
                    positionEnd: {
                        marginLeft: -4,
                        marginRight: 12,
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        // alignItems: 'stretch',
                    },
                    input: {
                        ...theme.typography.body2,
                        padding: '3px 0',
                        paddingLeft: 12,
                        paddingRight: 12,
                        height: 'auto',
                        // minHeight: 24,
                        display: 'flex',
                        alignItems: 'center',
                    },
                    multiline: {
                        padding: 0,
                    },
                    inputMultiline: {
                        paddingLeft: 12,
                        paddingRight: 12,
                    },
                },
            },
            MuiInputLabel: {
                defaultProps: {
                    // shrink: true,
                },
                styleOverrides: {
                    root: {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        maxWidth: '125%',
                        textOverflow: 'ellipsis',
                        height: '1.25em',
                        '&.Mui-disabled': {
                            color: theme.palette.text.secondary,
                        }
                    },
                    // formControl: {
                    //     transform: 'translate(0, 2px) scale(0.75)',
                    // },
                    // shrink: {
                    //     transform: 'translate(0, 2px) scale(0.75)',
                    // },
                    // sizeSmall: {
                    //     transform: 'translate(0, 2px) scale(0.75)',
                    // },
                    standard: {
                        transform: 'translate(0, 2px) scale(0.75) !important',
                        lineHeight: theme.typography.body2.lineHeight,
                    },
                    outlined: {
                        transform: 'translate(14px, 7px) scale(1)',
                    },
                },
            },
            MuiLink: {
                defaultProps: {
                    underline: 'hover',
                },
                styleOverrides: {
                    root: {
                        '&&[disabled]': {
                            color: theme.palette.text.primary,
                            pointerEvents: 'none',
                        }
                    }
                },
            },
            MuiListItemAvatar: {
                styleOverrides: {
                    root: {
                        minWidth: 36,
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: 32,
                        color: 'inherit',
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    },
                    secondary: {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }
                }
            },
            MuiListSubheader: {
                styleOverrides: {
                    root: {
                        lineHeight: '40px',
                        fontSize: theme.typography.caption.fontSize,
                        color: theme.palette.text.disabled,
                    }
                }
            },
            MuiMenu: {
                styleOverrides: {
                    root: {
                        zIndex: theme.zIndex.modal + 10,
                    },
                    paper: {
                        [theme.breakpoints.up('sm')]: {
                            maxHeight: 'max(60vh, 350px)'
                        },
                        '.select-menu &': {
                            marginTop: 2,
                        },
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        ...theme.typography.body2,
                        color: theme.palette.text.primary,
                        minHeight: 40,
                        paddingLeft: 12,
                        paddingRight: 24,
                        paddingTop: 3,
                        paddingBottom: 3,
                        [`&.Mui-selected`]: {
                            backgroundColor: alpha(theme.palette.primary.main, .1), // default is 0.08
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, .18), // default is 0.12
                            },
                            '&.Mui-focusVisible': {
                                backgroundColor: alpha(theme.palette.primary.main, .18), // default is 0.2
                            },
                        },
                        [theme.breakpoints.up('sm')]: {
                            minHeight: 40,
                        },
                        '& .MuiListItemText-primary': {
                            fontSize: theme.typography.body2.fontSize,
                        },
                        '& .MuiListItemIcon-root': {
                            minWidth: 32,
                        },
                    },
                },
            },
            MuiPaper: {
                defaultProps: {
                    elevation: 0
                },
                styleOverrides: {
                    root: {
                        transition: themeColorTransition,
                        backgroundImage: 'unset', // Disable opacity based on the elevation in dark mode
                    },
                },
            },
            MuiRadio: {
                defaultProps: {
                    color: 'primary',
                    size: 'small', // All radios default to small size
                },
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            // Reset transparent disabled color. Instead use opacity
                            color: theme.palette.text.secondary,
                            opacity: 0.5,
                        },
                    },
                    colorPrimary: {
                        '&.Mui-checked': {
                            color: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                                // Reset on touch devices, it doesn't add specificity
                                '@media (hover: none)': {
                                    backgroundColor: 'transparent',
                                },
                            },
                        },
                    },
                    colorSecondary: {
                        '&.Mui-checked': {
                            color: theme.palette.secondary.main,
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
                                // Reset on touch devices, it doesn't add specificity
                                '@media (hover: none)': {
                                    backgroundColor: 'transparent',
                                },
                            },
                        },
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'standard',
                    MenuProps: {
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                        className: 'select-menu',
                    },
                },
                styleOverrides: {
                    select: {
                        minHeight: 24,
                        paddingTop: 3,
                        paddingBottom: 3,
                        '&:focus': {
                            backgroundColor: undefined,
                        },
                        '&>*': {
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        },
                        '& .MuiListItemText-root': {
                            marginTop: 0,
                            marginBottom: 0,
                        },
                        // Make primary text smaller rendered value
                        '& .MuiListItemText-primary': {
                            fontSize: theme.typography.body2.fontSize,
                            lineHeight: '1.5',
                        },
                        // Hide secondary text in rendered value
                        '& .MuiListItemText-secondary': {
                            display: 'none',
                        },
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    thumb: {
                        background: 'white',
                        border: `2px solid ${theme.palette.info.main}`
                    },
                    rail: {
                        backgroundColor: theme.palette.action.selected,
                    },
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    fontSizeLarge: {
                        fontSize: pxToRem(32),
                    }
                }
            },
            MuiSwitch: {
                defaultProps: {
                    color: 'primary',
                    // size: 'small', // All switches default to small size
                },
                styleOverrides: {
                    root: {
                        // (2 * padding) + size
                        height: 2 * 12 + 14,
                        width: 2 * 12 + 30,
                    },
                    switchBase: {
                        color: '#999999',
                        padding: 13,
                        [`&.${switchClasses.checked} + .${switchClasses.track}`]: {
                            opacity: 1,
                            backgroundColor: 'transparent',
                        },
                        [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
                            opacity: 0.5,
                        },
                        [`&.${switchClasses.disabled}`]: {
                            opacity: 0.5,
                            color: '#999999',
                        },
                        [`&.${switchClasses.checked}`]: {
                            transform: 'translateX(16px)',
                        },
                    },
                    thumb: {
                        width: 12,
                        height: 12,
                    },
                    sizeSmall: {
                        marginLeft: 5,
                        padding: 7,
                        // (2 * padding) + size
                        width: 2 * 7 + 26,
                        height: 2 * 7 + 10,
                        '& .MuiSwitch-thumb': {
                            width: 8,
                            height: 8,
                        },
                        [`& .${switchClasses.switchBase}`]: {
                            padding: 8,
                        },
                    },
                    track: {
                        backgroundColor: 'transparent',
                        boxShadow: `0 0 0px 1px ${theme.palette.action.active}`,
                        opacity: 1,
                    },
                    colorPrimary: {
                        [`&.${switchClasses.checked}`]: {
                            color: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
                                '@media (hover: none)': {
                                    backgroundColor: 'transparent'
                                }
                            },
                            [`&.${switchClasses.disabled}`]: {
                                color: theme.palette.primary.main,
                            }
                        },
                        [`&.${switchClasses.checked} + .${switchClasses.track}`]: {
                            // backgroundColor: theme.palette.primary.main,
                            backgroundColor: 'transparent',
                            boxShadow: `0 0 0px 1px ${theme.palette.primary.main}`,
                        }
                    },
                },
            },
            MuiTab: {
                defaultProps: {
                    color: 'primary',
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiTooltip: {
                defaultProps: {
                    enterDelay: 100,
                    enterNextDelay: 100,
                },
                styleOverrides: {
                    popper: {
                        pointerEvents: 'none',
                    },
                    tooltip: {
                        ...theme.typography.caption,
                        backgroundColor: '#000000',
                        color: '#ffffff',
                        padding: '4px 12px',
                        [`.MuiTooltip-popper[data-popper-placement*="bottom"] &`]: {
                            marginTop: 8,
                        },
                        [`.MuiTooltip-popper[data-popper-placement*="left"] &`]: {
                            marginRight: 8,
                        },
                        [`.MuiTooltip-popper[data-popper-placement*="top"] &`]: {
                            marginBottom: 8,
                        },
                        [`.MuiTooltip-popper[data-popper-placement*="right"] &`]: {
                            marginLeft: 8,
                        },
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        hyphens: 'auto',
                        // Give simple a elements without a class attribute the link color by default
                        '& a:not([class])': {
                            color: `${theme.palette.primary.main}`,
                        },
                        '&.color-blue': {
                            color: light ? theme.palette.functionalBlue.main : theme.palette.functionalBlue.light,
                        },
                    },
                },
            },
            // labs package component overrides are not typed properly
            // ...{
            //     MuiTabPanel: {
            //         styleOverrides: {
            //             root: {
            //                 flex: 1,
            //             },
            //         }
            //     },
            // } as {},
        },
    };
};
export default overrides;
