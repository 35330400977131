// @description see exports

import { action, observable, runInAction, _allowStateChangesInsideComputed } from 'mobx';

import { getLogger } from '@dirico/utils/Logger';

const logger = getLogger('ResourceLoader');

export interface LoaderOptions<T> {
    maxAge?: number;
    disposer?: (oldValue: T) => void,
}

/**
 * This class simplifies loading resources asynchronously with mobx & react suspense
 */
export default class ResourceLoader<T> {

    @observable
    private _loaded = false;

    @observable
    private _loading = false;

    @observable
    private _timestamp = 0;

    @observable.ref
    private _value?: T;

    @observable.ref
    private _promise?: Promise<T>;

    @observable.ref
    private _error: unknown;

    public constructor(
        public readonly loader: () => Promise<T>,
        public readonly options: LoaderOptions<T> = {},
    ) { }

    /**
     * Boolean flag indicating if the loader finished executing and was successful
     */
    public get isLoaded() {
        return this._loaded;
    }

    public get isInvalidated() {
        return this._loaded && !this._promise;
    }

    public get isLoading() {
        return this._loading;
    }

    /**
     * Returns true if the value is outdated (max age exceeded) and should be fetched again
     */
    public get isOutdated() {
        return this.options.maxAge && Date.now() > this._timestamp + this.options.maxAge;
    }

    /**
     * Error result of the loader it failed to load the value
     */
    public get error() {
        return this._error;
    }

    /**
     * Get value or undefined, if it was not loaded yet
     */
    public get value() {
        return this._value;
    }

    private set value(value: T | undefined) {
        if (this._value && this.options.disposer) {
            this.options.disposer(this._value);
        }
        this._value = value;
    }

    /**
     * Timestamp when this value was last updated
     */
    public get timestamp() {
        return this._timestamp;
    }

    /**
     * Clears the cached value and promise
     */
    @action
    public clear() {
        this._loaded = false;
        this._promise = undefined;
        this.value = undefined;
        this._timestamp = 0;
    }

    /**
     * Invalidates the value, causing it to be loaded again on next access
     */
    @action
    public invalidate() {
        // Reset promise to cause refetch
        this._promise = undefined;
    }

    /**
     * Returns a promise which will resolve to the value.
     * If the value is already loaded, it will return the already resolved promise.
     */
    public getAsync = () => {
        if (!this._promise || this.isOutdated && !this._loading) {
            this.startLoading();
        }
        return this._promise!;
    };

    @action
    private startLoading() {
        _allowStateChangesInsideComputed(() => {
            this._loading = true;
            this._promise = Promise.resolve().then(this.loader);
            // The handling of the promise result is not included in the saved promise
            // This is to make sure we do not end up with unhandled promise errors when lazy access is used
            this._promise.then(action(value => {
                this._loading = false;
                this._loaded = true;
                this.value = value;
                this._error = undefined;
                this._timestamp = Date.now();
            }), action(error => {
                this._loading = false;
                this._loaded = false;
                this.value = undefined;
                this._timestamp = Date.now();
                this._error = error;
                logger.warn('ResourceLoader Error:', { error });
            }));
        });
    }

    @action
    public setValue(value: T) {
        this._promise = Promise.resolve(value);
        this._loaded = true;
        this.value = value;
        this._timestamp = Date.now();
    }

    /**
     * Returns value if loaded, otherwise starts loading and returns undefined
     */
    public get lazyValue() {
        this.getAsync();
        if (this._loaded) {
            return this._value!;
        } else {
            return undefined;
        }
    }

    public set lazyValue(value: T | undefined) {
        if (!value) {
            throw new Error(process.env.NODE_ENV === 'production' ? undefined : `Value cannot be set to undefined`);
        }
        this.setValue(value);
    }

    /**
     * Suspense-Loads the resource and returns a tuple containing either the value or an error
     */
    public get lazyData() {
        return [this.lazyValue, this.error] as const;
    }

    /**
     * Returns value if loaded, otherwise throws loading promise.
     * Should be used for `React.Suspense` functionality
     */
    public get suspenseValue(): T {
        const promise = this.getAsync();
        if (this._loaded) {
            return this._value!;
        } else if (this._error) {
            throw this._error;
        } else {
            throw promise;
        }
    }

    /**
     * Suspense-Loads the resource and returns a tuple containing either the value or an error
     */
    public get suspenseData() {
        this.getAsync();
        return [this._value, this._error] as const;
    }
}

/**
 * This class simplifies loading multiple resources asynchronously with mobx & react suspense by some kind of id
 */
export class MultiResourceLoader<T, ID> {

    @observable
    private _loaders = new Map<ID, ResourceLoader<T>>();

    constructor(
        /** Loader function */
        public readonly loader: (id: ID) => Promise<T>,
        public readonly options: LoaderOptions<T> = {},
    ) { }

    public get loaders() {
        return Array.from(this._loaders.values());
    }

    /**
     * Removes all resource loaders
     */
    public clear() {
        this._loaders.clear();
    }

    /**
     * Removes the resource loader for a specific id
     */
    public delete(id: ID) {
        this._loaders.delete(id);
    }

    /**
     * Invalidates all loaded values
     */
    public invalidateAll() {
        for (const value of this._loaders.values()) {
            value.invalidate();
        }
    }

    /**
     * Returns the resource loader for a specific id
     */
    public getLoader(id: ID, createIfMissing: false): ResourceLoader<T> | undefined;
    public getLoader(id: ID, createIfMissing?: true): ResourceLoader<T>;
    public getLoader(id: ID, createIfMissing = true) {
        let ressource = this._loaders.get(id);
        if (!ressource && createIfMissing) {
            runInAction(() => {
                ressource = new ResourceLoader(() => this.loader(id), this.options);
                this._loaders.set(id, ressource);
            });
        }
        return ressource;
    }

    /**
     * Invalidates the value, causing it to be loaded again on next access
     */
    public invalidate(id: ID) {
        this.getLoader(id).invalidate();
    }

    /**
     * Returns a promise which will resolve to the value.
     * If the value is already loaded, it will return the already resolved promise.
     */
    public get(id: ID) {
        return this.getLoader(id).getAsync();
    }

    /**
     * Returns cached value, otherwise undefined
     */
    public getCached(id: ID) {
        return this.getLoader(id).value;
    }

    /**
     * Returns value if loaded, otherwise starts loading and returns undefined
     */
    public getLazyValue(id: ID) {
        return this.getLoader(id).lazyValue;
    }

    /**
     * Returns a tuple of [value, error] depending on what is currently present
     */
    public getLazyData(id: ID) {
        return this.getLoader(id).lazyData;
    }

    /**
     * Returns value if loaded, otherwise throws loading promise.
     * Should be used for `React.Suspense` functionality
     */
    public getSuspenseValue(id: ID) {
        return this.getLoader(id).suspenseValue;
    }

    /**
     * Returns a tuple of [value, error] depending on what is currently present.
     * Throws promise when value is not loaded yet for React suspense.
     */
    public getSuspenseData(id: ID) {
        return this.getLoader(id).suspenseData;
    }
}
