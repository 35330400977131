/* eslint-disable no-restricted-syntax */
// @description Temporary global logging solution - should be improved
// @authors bzeutzheim

import { observable, action, runInAction } from 'mobx';

import autobind from '@dirico/utils/autobind-decorator';

import './performance-polyfill';

declare global {
    // eslint-disable-next-line no-var
    var _logger: typeof Logger;
}

export type ReadonlyRecord<K extends keyof any, T> = {
    readonly [P in K]: T;
};

type StackType = string | undefined;
// type StackType = StackFrame[];

type Primitive = string | number | boolean | Date;

export interface LogData {
    /**
     * Each entry in this record is logged inside a collapsed group within the parent log message.
     * Also, this data is will be passed on to logging hooks for ingestion into eg. Application Insights
     */
    data?: Record<string, any>;
    /** Log level */
    level: LogLevel;
    /** Name of the logger which created this message */
    loggerName: string;
    /** The full, stringified message */
    message: string;
    /** Whether this message was logged or skipped by it's logger */
    shouldLog: boolean;
    /** Stack trace for where this message was logged */
    stack: StackType;
    /** If there is any error associated with this log message, pass it here */
    error?: unknown;
}

export interface ILoggerHook {
    log(data: LogData): void;
}

export enum LogLevel {
    trace = 1,
    debug = 2,
    info = 3,
    warn = 4,
    error = 5,
    critical = 6,
    silent = 10,
}

export type LogLevelString = keyof typeof LogLevel;

export type LogLevelInput = LogLevel | LogLevelString;

export function parseLogLevel(level: LogLevel): LogLevel;
export function parseLogLevel(level: LogLevelInput | undefined): LogLevel | undefined;
/** Converts LogLevelInput into LogLevel enum value */
export function parseLogLevel(level: LogLevelInput | undefined) {
    return typeof level === 'string' ? LogLevel[level as any] :
        typeof level === 'number' ? level as LogLevel :
            undefined;
}

const LogLevelStyle: ReadonlyRecord<LogLevel, string> = {
    [LogLevel.trace]: 'color: violet;',
    [LogLevel.debug]: 'color: #4D88FF;',
    [LogLevel.info]: '',
    [LogLevel.warn]: 'color: orange;',
    [LogLevel.error]: 'color: red;',
    [LogLevel.critical]: 'color: red; text-decoration: underline; font-weight: bold;',
    [LogLevel.silent]: '',
};

export interface LoggerOptions {
    prependLevel?: boolean;
    prependTimestamp?: false | 'time' | 'dateTime';
    logDebugData?: boolean;
    traceDebug?: boolean;
    traceInfo?: boolean;
}

export interface LogOptions {
    /**
     * CSS infos to apply to the log message
     */
    css?: string[];
    /**
     * Each entry in this record is logged inside a collapsed group within the parent log message.
     * Also, this data is will be passed on to logging hooks for ingestion into eg. Application Insights
     */
    data?: Record<string, any>;
    /**
     * Same as {@link data}, but instead this information will only be logged and is intended for debugging and not for any kind of persistence
     */
    debugData?: Record<string, any>;
    /**
     * Additional console log messages which should be printed inside the collapsed group of the parent log message.
     */
    debugLogs?: unknown[][];
    /**
     * If there is any error associated with this log message, pass it here
     */
    error?: unknown;
    /**
     * How many stack frames to skip when logging stack trace
     */
    skipStackFrames?: number;
}

export interface IPerfMeasurer {
    /** stops the performance measuring block */
    end(): void;
    /** places a new mark and measurement for this performance measuring block */
    step(step: string): void;
}

// const stackTraceGps = new StackTraceGps();

// Grab console functions to prevent later overrides to be used
const {
    error: consoleError,
    groupCollapsed: consoleGroupCollapsed,
    groupEnd: consoleGroupEnd,
    info: consoleInfo,
    log: consoleLog,
    trace: consoleTrace,
    warn: consoleWarn,
} = console;

type LogArgs = [message: string, ...rest: unknown[]];

function getDefaultLogLevel(): LogLevel {
    if (typeof window === 'undefined') {
        return LogLevel.info;
    }
    // Check if a default level was persisted to localStorage
    const persistedLevel = parseLogLevel(window.localStorage.getItem('logger:') as LogLevelString);
    if (persistedLevel) {
        return persistedLevel;
    }
    if (window.location.hostname === 'localhost' || process.env.NODE_ENV !== 'production') {
        // Always use info as default for localhost testing
        return LogLevel.info;
    }
    // If none of the above apply, use error level by default
    return LogLevel.error;
}

/** */
export class Logger {

    public static readonly loggers = observable.map<string, Logger>(undefined, { deep: false });

    public static readonly hooks = new Set<ILoggerHook>();

    public static readonly LogLevel = LogLevel;

    @observable
    public static defaultLevel: LogLevel = getDefaultLogLevel();

    public static readonly globalOptions: LoggerOptions = observable({
        prependLevel: false,
        prependTimestamp: false,
        logDebugData: true,
        traceDebug: true,
        traceInfo: true,
    });

    // Members

    public readonly name: string;

    @observable
    public shortName: string;

    @observable
    private _defaultLevel?: LogLevel;

    @observable
    private _level?: LogLevel;

    @observable
    public options: LoggerOptions = {};

    private readonly _localStorageKey: string;

    constructor(identifier: string, defaultLevel?: LogLevelInput) {
        this.shortName = this.name = identifier;
        this._localStorageKey = `logger:${this.name}`;
        this._defaultLevel = parseLogLevel(defaultLevel);
        this._level = typeof window === 'undefined' ? undefined : parseLogLevel(window.localStorage.getItem(this._localStorageKey) as LogLevelString);
        // this._level = LogLevel.trace;
    }

    public static getLogger(identifier: string) {
        if (!identifier) {
            identifier = '';
        }
        let logger = Logger.loggers.get(identifier);
        if (!logger) {
            runInAction(() => {
                logger = new Logger(identifier);
                Logger.loggers.set(identifier, logger);
            });
        }
        return logger!;
    }

    @action
    public static setDefaultLogLevel(level: LogLevelInput, persist = false) {
        const logLevel = parseLogLevel(level);
        if (logLevel) {
            Logger.defaultLevel = logLevel;
            if (persist && typeof window !== 'undefined') {
                window.localStorage.setItem('logger:', LogLevel[logLevel]);
            }
        }
    }

    public getLogger(identifier: string) {
        return Logger.getLogger(identifier);
    }

    public get configuredLevel() {
        return this._level;
    }

    public get level() {
        return this._level || this.defaultLevel;
    }

    public get defaultLevel() {
        return this._defaultLevel || Logger.defaultLevel;
    }

    public get traceInfo() {
        return this.options.traceInfo ?? Logger.globalOptions.traceInfo ?? true;
    }

    public get traceDebug() {
        return this.options.traceDebug ?? Logger.globalOptions.traceDebug ?? true;
    }

    public get logDebugData() {
        return this.options.logDebugData ?? Logger.globalOptions.logDebugData ?? true;
    }

    public get prependLevel() {
        return this.options.prependLevel ?? Logger.globalOptions.prependLevel ?? false;
    }

    public get prependTimestamp() {
        return this.options.prependTimestamp ?? Logger.globalOptions.prependTimestamp ?? false;
    }

    @action
    public setShortIdentifier(shortIdentifier: string) {
        this.shortName = shortIdentifier;
        return this;
    }

    @action
    public setLevel(level: LogLevelInput, persist = false) {
        const levelNum = parseLogLevel(level);
        if (levelNum) {
            this._level = levelNum;
            if (persist && typeof window !== 'undefined') {
                window.localStorage.setItem(this._localStorageKey, LogLevel[levelNum]);
            }
        } else {
            this._level = undefined;
            if (persist && typeof window !== 'undefined') {
                window.localStorage.removeItem(this._localStorageKey);
            }
        }
        return this;
    }

    @action
    public clearLevel(persist = true) {
        this._level = undefined;
        if (persist && typeof window !== 'undefined') {
            window.localStorage.removeItem(this._localStorageKey);
        }
    }

    @action
    public setDefaultLevel(level: LogLevelInput) {
        const levelNum = parseLogLevel(level);
        if (levelNum) {
            this._defaultLevel = levelNum;
        }
        return this;
    }

    public shouldLog(level: LogLevelInput) {
        const levelNum = parseLogLevel(level);
        if (!levelNum) {
            return false;
        }
        return levelNum >= this.level;
    }

    public callHooks(data: LogData) {
        for (const hook of Logger.hooks) {
            hook.log(data);
        }
    }

    public triggerHooks(level: LogLevel, args: LogArgs, stack: StackType, error?: unknown) {
        if (Logger.hooks.size === 0) {
            return;
        }
        // const mappedStack = await Promise.all(stack.map(frame => stackTraceGps.pinpoint(frame)));
        this.callHooks({
            loggerName: this.name,
            level,
            shouldLog: this.shouldLog(level),
            message: args[0],
            stack,
            error,
        });
    }

    private _stringifyMessage(messageArgs: Primitive[]): string {
        return !messageArgs ? '' : messageArgs.map(x =>
            x instanceof Date ? x.toISOString() :
                x
        ).join(' ');
    }

    private _getStack(error: Error, skipStackFrames = 0) {
        // return ErrorStackParser.parse(error).slice(1);
        return error.stack?.split('\n').slice(2 + skipStackFrames).join('\n');
    }

    private _getErrorLogArgs(
        errorOrMessage: string | Error | unknown | Primitive[],
        messageOrOptions?: string | Primitive[] | LogOptions,
        options?: LogOptions
    ): [string | Primitive[], LogOptions] {
        if (typeof messageOrOptions === 'string' || Array.isArray(messageOrOptions)) {
            // 1st arg is error, 2nd arg is message, 3rd arg is options or undefined
            let msg = messageOrOptions;
            const error = errorOrMessage;
            if (isError(error)) {
                if (typeof msg === 'string') {
                    msg = msg + ': ' + error.message;
                } else if (msg) {
                    msg = [...msg, error.message];
                } else {
                    msg = error.message;
                }
            }
            return [msg, { error, ...options }];
        } else {
            // 1st arg is message, 2nd arg is options or undefined
            return [errorOrMessage as string | Primitive[], messageOrOptions || {}];
        }
    }

    public critical(error: Error | unknown, message: string | Primitive[], options?: LogOptions): void;
    public critical(message: string | Primitive[], options?: LogOptions): void;
    @autobind
    public critical(errorOrMessage: Error | unknown | string | Primitive[], messageOrOptions?: string | Primitive[] | LogOptions, _options?: LogOptions) {
        const [message, options] = this._getErrorLogArgs(errorOrMessage, messageOrOptions, _options);
        const {
            shouldLog,
            hasDetails,
            logArgs,
        } = this._prepareLog(LogLevel.critical, message, options, new Error());
        if (!shouldLog) {
            return;
        }
        if (hasDetails) {
            consoleGroupCollapsed(...logArgs);
            try {
                this._logDetails(options);
                // Log trace
                consoleGroupCollapsed(`%cExpand to see trace for this message`, 'font-style: italic; color: #808080;');
                consoleTrace();
                consoleGroupEnd();
            } finally {
                // Close log group
                consoleGroupEnd();
            }
        } else {
            consoleError(...logArgs);
        }
    }

    public error(error: Error, message: string | Primitive[], options?: LogOptions): void;
    public error(message: string | Primitive[], options?: LogOptions): void;
    @autobind
    public error(errorOrMessage: Error | string | Primitive[], messageOrOptions?: string | Primitive[] | LogOptions, _options?: LogOptions) {
        const [message, options] = this._getErrorLogArgs(errorOrMessage, messageOrOptions, _options);
        const {
            shouldLog,
            hasDetails,
            logArgs,
        } = this._prepareLog(LogLevel.error, message, options, new Error());
        if (!shouldLog) {
            return;
        }
        if (hasDetails) {
            consoleGroupCollapsed(...logArgs);
            try {
                this._logDetails(options);
                // Log trace
                consoleGroupCollapsed(`%cExpand to see trace for this message`, 'font-style: italic; color: #808080;');
                consoleTrace();
                consoleGroupEnd();
            } finally {
                // Close log group
                consoleGroupEnd();
            }
        } else {
            consoleError(...logArgs);
        }
    }

    @autobind
    public warn(message: string | Primitive[], options: LogOptions = {}) {
        const {
            shouldLog,
            hasDetails,
            logArgs,
        } = this._prepareLog(LogLevel.warn, message, options, new Error());
        if (!shouldLog) {
            return;
        }
        if (hasDetails) {
            consoleGroupCollapsed(...logArgs);
            try {
                this._logDetails(options);
                // Log trace
                consoleGroupCollapsed(`%cExpand to see trace for this message`, 'font-style: italic; color: #808080;');
                consoleTrace();
                consoleGroupEnd();
            } finally {
                // Close log group
                consoleGroupEnd();
            }
        } else {
            consoleWarn(...logArgs);
        }
    }

    @autobind
    public info(message: string | Primitive[], options: LogOptions = {}) {
        const {
            shouldLog,
            hasDetails,
            logArgs,
        } = this._prepareLog(LogLevel.info, message, options, new Error());
        if (!shouldLog) {
            return;
        }
        if (hasDetails) {
            consoleGroupCollapsed(...logArgs);
            try {
                this._logDetails(options);
                if (this.traceDebug) {
                    // Log trace
                    consoleGroupCollapsed(`%cExpand to see trace for this message`, 'font-style: italic; color: #808080;');
                    consoleTrace();
                    consoleGroupEnd();
                }
            } finally {
                // Close log group
                consoleGroupEnd();
            }
        } else {
            consoleInfo(...logArgs);
        }
    }

    @autobind
    public debug(message: string | Primitive[], options: LogOptions = {}) {
        const {
            shouldLog,
            hasDetails,
            logArgs,
        } = this._prepareLog(LogLevel.debug, message, options, new Error());
        if (!shouldLog) {
            return;
        }
        if (hasDetails) {
            consoleGroupCollapsed(...logArgs);
            try {
                this._logDetails(options);
                if (this.traceDebug) {
                    // Log trace
                    consoleGroupCollapsed(`%cExpand to see trace for this message`, 'font-style: italic; color: #808080;');
                    consoleTrace();
                    consoleGroupEnd();
                }
            } finally {
                // Close log group
                consoleGroupEnd();
            }
        } else {
            consoleTrace(...logArgs);
        }
    }

    @autobind
    public trace(message: string | Primitive[], options: LogOptions = {}) {
        const {
            shouldLog,
            hasDetails,
            logArgs,
        } = this._prepareLog(LogLevel.trace, message, options, new Error());
        if (!shouldLog) {
            return;
        }
        if (hasDetails) {
            consoleGroupCollapsed(...logArgs);
            try {
                this._logDetails(options);
                if (this.traceDebug) {
                    // Log trace
                    consoleGroupCollapsed(`%cExpand to see trace for this message`, 'font-style: italic; color: #808080;');
                    consoleTrace();
                    consoleGroupEnd();
                }
            } finally {
                // Close log group
                consoleGroupEnd();
            }
        } else {
            consoleTrace(...logArgs);
        }
    }

    @autobind
    public log(logLevelInput: LogLevelInput, message: string | Primitive[], options: LogOptions = {}) {
        const logLevel = parseLogLevel(logLevelInput);
        if (logLevel === undefined) {
            throw new Error(`Invalid log level argument ${logLevelInput}`);
        }
        if (logLevel === LogLevel.silent) {
            return;
        }
        const {
            shouldLog,
            hasDetails,
            logArgs,
        } = this._prepareLog(logLevel, message, options, new Error());
        if (!shouldLog) {
            return;
        }
        if (hasDetails) {
            consoleGroupCollapsed(...logArgs);
            try {
                this._logDetails(options);
                if (this.traceDebug) {
                    // Log trace
                    consoleGroupCollapsed(`%cExpand to see trace for this message`, 'font-style: italic; color: #808080;');
                    consoleTrace();
                    consoleGroupEnd();
                }
            } finally {
                // Close log group
                consoleGroupEnd();
            }
        } else {
            consoleLog(...logArgs);
        }
    }

    /**
     * Logs additional data from LogOptions
     */
    private _logDetails(options: LogOptions) {
        if (options.error) {
            consoleError(options.error);
            if (typeof options.error === 'object' && options.error.constructor !== Error) {
                consoleLog(`Error properties:`, { ...options.error });
            }
        }
        if (options.debugLogs) {
            for (const extraLog of options.debugLogs) {
                consoleLog(...extraLog);
            }
        }
        if (options.data) {
            this._logData(options.data, 'Data');
        }
        if (options.debugData && this.logDebugData) {
            this._logData(options.debugData, 'Debug data');
        }
    }

    /**
     * Logs a record of data with a prefix before keys
     */
    private _logData(data: Record<string, any>, prefix: string) {
        for (const [key, value] of Object.entries(data)) {
            if (value === undefined) {
                continue;
            }
            if (typeof value === 'object' || typeof value === 'string' && value.length > 500) {
                consoleGroupCollapsed(`${prefix} ${key}${isNonPlainObject(value) ? ` #${getObjectId(value)}` : ''}:`);
                consoleLog(value);
                consoleGroupEnd();
            } else {
                consoleLog(`${prefix} ${key}:`, value);
            }
        }
    }

    /**
     * Common handling for any kind of log message
     */
    private _prepareLog(level: LogLevel, message: string | Primitive[], options: LogOptions, stackError: Error) {
        const stack = this._getStack(stackError, options.skipStackFrames);
        const shouldLog = this.shouldLog(level);

        const messageParts = Array.isArray(message) ? message : [message];
        const joinedMessage = this._stringifyMessage(messageParts);

        this.callHooks({
            data: options.data,
            error: options.error,
            level,
            loggerName: this.name,
            message: joinedMessage.replace(/%c/g, ''),
            shouldLog,
            stack,
        });

        let prefix = '';
        if (this.prependLevel) {
            prefix += `[${LogLevel[level]}] `;
        }
        if (this.prependTimestamp) {
            prefix += new Date().toISOString().slice(this.prependTimestamp === 'dateTime' ? 0 : 11, 19) + ' ';
        }
        // Prefix with logger name
        prefix += `[${this.shortName}] `;
        const logArgs = [
            `${prefix.length > 0 ? `%c${prefix}%c` : ''}${joinedMessage}`,
            LogLevelStyle[level],
            '',
            ...(options.css || []),
        ];

        const hasDetails = this.traceDebug || options.data || this.logDebugData && (options.debugLogs || options.debugData);
        return { shouldLog, hasDetails, logArgs };
    }

    public measurePerformance(baseName: string, logLevel: LogLevelInput = LogLevel.trace, stepLogLevel: LogLevelInput = LogLevel.silent): IPerfMeasurer {
        if (typeof window !== 'undefined' && window.performance.mark !== undefined && window.performance.measure !== undefined) {
            return new WindowPerformanceMeasurer(this, baseName, logLevel, stepLogLevel);
        } else {
            return new DatePerformanceMeasurer(this, baseName, logLevel, stepLogLevel);
        }
    }

    public getObjectId = getObjectId;

}

class DatePerformanceMeasurer implements IPerfMeasurer {

    private readonly startTime: number;

    private lastStepName: string;

    private lastStepTime: number;

    private stepMeasures: [string, string, number][] = [];

    constructor(
        public readonly logger: Logger,
        public readonly name: string,
        public logLevel: LogLevelInput = LogLevel.trace,
        public stepLogLevel: LogLevelInput = LogLevel.silent,
    ) {
        this.lastStepName = 'start';
        this.lastStepTime = this.startTime = Date.now();
    }

    public step(stepName: string) {
        const stepTime = Date.now();
        const fullStepName = `${this.name}: ${this.lastStepName}>${stepName}`;
        const stepDuration = stepTime - this.lastStepTime;
        this.stepMeasures.push([this.lastStepName, stepName, stepDuration]);
        this.lastStepTime = stepTime;
        this.lastStepName = stepName;
        if (this.stepLogLevel < LogLevel.silent) {
            this.logger.log(this.stepLogLevel, `Measure "${fullStepName}" took ${stepDuration}ms`);
        }
    }

    public end() {
        this.step('end');
        const totalDuration = this.lastStepTime - this.startTime;
        this.logger.log(this.logLevel, `Measured "${this.name}" over ${totalDuration}ms`, {
            data: this.stepMeasures.length === 1 ? undefined : { steps: this.stepMeasures }
        });
    }
}

class WindowPerformanceMeasurer implements IPerfMeasurer {

    private readonly id: string;

    private readonly startMark: PerformanceMark;

    private lastStepName: string;
    private lastStepMark: PerformanceMark;

    private stepMeasures: [string, string, number][] = [];

    constructor(
        public readonly logger: Logger,
        public readonly name: string,
        public logLevel: LogLevelInput = LogLevel.trace,
        public stepLogLevel: LogLevelInput = LogLevel.silent,
    ) {
        this.id = name + '-' + Math.random();
        this.lastStepName = 'start';
        this.startMark = this.lastStepMark = window.performance.mark(this.getMarkName(this.lastStepName));
    }

    private getMarkName(step: string) {
        return this.id + '-' + step;
    }

    public step(stepName: string) {
        const stepMark = window.performance.mark(this.getMarkName(stepName));
        const fullStepName = `${this.name}: ${this.lastStepName}>${stepName}`;
        const measure = window.performance.measure(fullStepName, this.getMarkName(this.lastStepName), stepMark.name);
        const stepDuration = measure?.duration ?? stepMark.startTime - this.lastStepMark.startTime;
        this.stepMeasures.push([this.lastStepName, stepName, stepDuration]);
        this.lastStepMark = stepMark;
        this.lastStepName = stepName;
        if (this.stepLogLevel < LogLevel.silent) {
            this.logger.log(this.stepLogLevel, `Measure "${fullStepName}" took ${stepDuration}ms`);
        }
    }

    public end() {
        this.step('end');
        const measure = window.performance.measure(this.name, this.startMark.name, this.getMarkName(this.lastStepName));
        const totalDuration = measure?.duration ?? this.lastStepMark.startTime - this.startMark.startTime;
        this.logger.log(this.logLevel, `Measured "${this.name}" over ${totalDuration}ms`, {
            data: this.stepMeasures.length === 1 ? undefined : { steps: this.stepMeasures }
        });
    }
}

function isError(error: any): error is Error {
    return Boolean(
        error &&
        typeof error === 'object' &&
        'stack' in error &&
        'message' in error &&
        typeof error.message === 'string'
    );
}

/** Returns true, if the passed value is an object, but not a plain one (eg. {}, Date, String, ...) */
export function isNonPlainObject(value: any) {
    if (!value || typeof value !== 'object' || Array.isArray(value)) {
        return false;
    }
    const proto = Object.getPrototypeOf(value);
    return (
        proto !== Object.prototype &&
        proto !== Date.prototype
    );
}

let nextObjectId = 0;
const objectIdMap = new WeakMap<object, number>();

/** Returns a unique index for any object, by caching it inside a weak map */
export function getObjectId(value: object) {
    let id = objectIdMap.get(value);
    if (id === undefined) {
        id = nextObjectId++;
        objectIdMap.set(value, id);
    }
    return id;
}

export default Logger;

export const defaultLogger = Logger.getLogger('').setShortIdentifier('default');

export const getLogger = Logger.getLogger.bind(Logger);

// /** */
// export function patchConsole() {
//     console.log = defaultLogger.info.bind(defaultLogger);
//     console.info = defaultLogger.info.bind(defaultLogger);
//     console.debug = defaultLogger.debug.bind(defaultLogger);
// }

/**
 * Can be used to pass uncaught errors to the logger error hooks:  
 * `globalThis.addEventListener('error', globalErrorEventHandler);`.
 * 
 * However, this might not be necessary, because Application Insights already logs uncaught exceptions properly.
 */
export function globalErrorEventHandler(event: ErrorEvent) {
    // Cross origin error - pass this on to the browser console
    if (event.message.toLowerCase().includes('script error:')) {
        return;
    }
    // Trigger logger hooks so that uncaught exceptions are forwarded to AI
    defaultLogger.triggerHooks(LogLevel.error, ['Uncaught exception'], event.error.stack, event.error);
}

/** */
export function registerGlobalErrorHandler() {
    globalThis.addEventListener('error', globalErrorEventHandler);
}

globalThis._logger = Logger;

// defaultLogger.log('warn', ['bool', true, 'number', 3, 'date', new Date()], {
//     error: new Error(),
//     data: {
//         data1: 3,
//         data2: { obj: 1 },
//     },
// });
