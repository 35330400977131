// @description Lazy loads the app while showing a loading animation

import React from 'react';
import {
    CircularProgress,
    Typography,
    CssBaseline,
} from '@mui/material';

import { VStack } from '@dirico/material/Stack';
import ShowAfterDelay from '@dirico/components/ShowAfterDelay';

import createApplicationRoot from '@dirico/frontend-core/core/createApplicationRoot';

import AppThemeProvider from '@dirico/main-frontend/core/theme/AppThemeProvider';

import './AppLoader.scss';

// WARNING: Do not include any application core references here as this module is responsible for showing the splash screen

const LazyApp = createApplicationRoot(
    () => import(/* webpackChunkName: "core", webpackPreload: true */ './DiricoApplicationRoot')
        .then(async ({ createApplicationResource, DiricoApplicationLoader }) => {
            const appLoader = createApplicationResource();
            // Await initialization of application
            await appLoader.getAsync();
            return {
                appLoader,
                node: <DiricoApplicationLoader loader={appLoader} />,
            };
        }),
    (accept) => module.hot!.accept('./DiricoApplicationRoot', accept)
);

export const LoaderSplashScreen = () => (
    <ShowAfterDelay delay={300}>
        <VStack verticalPosition='middle' horizontalAlign='center' spacing={4} sx={{ height: '100%' }}>
            <CircularProgress size={64} color='primary' />
            <Typography variant='h4' style={{ marginTop: 16 }}>Loading app...</Typography>
        </VStack>
    </ShowAfterDelay>
);

const AppLoader = () => (
    <AppThemeProvider>
        <CssBaseline />
        <React.Suspense fallback={<LoaderSplashScreen />}>
            <LazyApp />
        </React.Suspense>
    </AppThemeProvider>
);

export default AppLoader;
