// @description This file contains the tokens required for dependency injection, routing, etc.

/* eslint-disable import/first, jsdoc/require-jsdoc */
import { createToken } from 'tpendency';

import type { LocationStore } from '@dirico/utils/LocationStore';
import type EvalService from '@dirico/utils/services/EvalService';
import type ConditionCheckerService from '@dirico/utils/services/conditionChecker/ConditionCheckerService';
import type ValidationService from '@dirico/utils/services/ValidationService';
import type ModalService from '@dirico/utils/services/ModalService';

// ============================================================
// IMPORTS
// ============================================================

import type ApplicationInjector from './services/ApplicationInjector';
import type AppTranslator from './services/AppTranslator';
import type AuthService from './services/auth/AuthService';
import type { BaseApplicationServices } from './BaseApplication';
import type LocalStorageService from './services/LocalStorageService';
import type ViewManager from './services/ViewManager';
import type ViewRouter from './services/ViewRouter';
import type { ViewTokenRouter } from './types';
//
// CORE SERVICES
//
import type ApplicationInsightsService from './services/insights/ApplicationInsightsService';
import type MetricsService from './services/insights/MetricsService';
import type ToastService from './services/ToastService';
import type { IViewTitleService, ViewTitleServiceOptions } from './services/ViewTitleService';

// ============================================================
// TOKENS
// ============================================================

export const InjectorToken = createToken<ApplicationInjector>('Injector');
export const TranslatorToken = createToken<AppTranslator>('Translator');
export const AuthToken = createToken<AuthService>('Auth');
export const BaseServicesToken = createToken<BaseApplicationServices>('BaseApplicationServices');
export const LocalStorageServiceToken = createToken<LocalStorageService>('LocalStorageService');
export const ViewManagerToken = createToken<ViewManager>('ViewManager');
export const ViewRouterToken = createToken<ViewRouter>('ViewRouter');

export const LocationStoreToken = createToken<LocationStore>('LocationStore');
export const RouterToken = createToken<ViewTokenRouter>('Router');

// CORE SERVICES

export const ApplicationInsightsServiceToken = createToken<ApplicationInsightsService>('ApplicationInsightsService');
export const ConditionCheckerServiceToken = createToken<ConditionCheckerService>('ConditionCheckerService');
export const EvalServiceToken = createToken<EvalService>('EvalService');
export const MetricsServiceToken = createToken<MetricsService>('MetricsService');
export const ModalsServiceToken = createToken<ModalService>('ModalService');
export const ToastServiceToken = createToken<ToastService>('ToastService');
export const ValidationServiceToken = createToken<ValidationService>('ValidationService');
export const ViewTitleServiceOptionsToken = createToken<ViewTitleServiceOptions>('ViewTitleServiceOptions');
export const ViewTitleServiceToken = createToken<IViewTitleService>('ViewTitleService');
