// @description A decorator to automatically bind the this keyword of the method to the component instance.
// @authors bzeutzheim

/* eslint-disable @typescript-eslint/ban-ts-comment */
const PROTO_SYMBOL: symbol = (global as any).LIVE_REBIND_PROTO_SYMBOL || ((global as any).LIVE_REBIND_PROTO_SYMBOL = Symbol('isPrototype'));

/**
 * Use this decorator for functions that are used in render calls and are ok to be updated on next access.
 */
export default function autobind<T extends Function>(_target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<T>): TypedPropertyDescriptor<T> | void {
    if (!descriptor || (typeof descriptor.value !== 'function')) {
        throw new TypeError(`Only methods can be decorated with bind decorators. <${propertyKey}> is not a method!`/*`*/);
    }
    return {
        configurable: true,
        // eslint-disable-next-line
        get(this: T): T {
            // Escape-hatch to still get the actual prototype function and not a wrapper
            // @ts-ignore-next-line
            if (this[PROTO_SYMBOL]) { return descriptor.value; }
            let bound: T & { originalFn: T; disableRebinding?: boolean; } = descriptor.value!.bind(this);
            bound.originalFn = descriptor.value!;
            Object.defineProperty(this, propertyKey, {
                // eslint-disable-next-line
                get(this: T): T {
                    const proto = Object.getPrototypeOf(this);  // Get prototype
                    proto[PROTO_SYMBOL] = true;                 // Set flag to prevent returning a new wrapper function
                    const protoFn = proto[propertyKey];         // Get original prototype function
                    proto[PROTO_SYMBOL] = false;                // Clear flag
                    // Check if prototype changed
                    if (bound.originalFn !== protoFn && !bound.disableRebinding) {
                        bound = protoFn.bind(this);
                        bound.originalFn = protoFn;
                    }
                    return bound;
                },
                // eslint-disable-next-line
                set(this: T, value: any) {
                    bound = value;
                    bound.disableRebinding = true;
                },
                configurable: true,
            });
            return bound;
        }
    };
}

/**
 * Use this decorator for stuff like timers etc. that are not updated each render call (warning: could performance impact).
 */
export function autobindLive<T extends Function>(_target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<T>): TypedPropertyDescriptor<T> | void {
    if (!descriptor || (typeof descriptor.value !== 'function')) {
        throw new TypeError(`Only methods can be decorated with bind decorators. <${propertyKey}> is not a method!`/*`*/);
    }
    return {
        configurable: true,
        // eslint-disable-next-line
        get(this: T): T {
            // Escape-hatch to still get the actual prototype function and not a wrapper
            // @ts-ignore-next-line
            if (this[PROTO_SYMBOL]) { return descriptor.value; }
            // Create new function which always checks the current prototype for the correct prototype function to call
            const wrapper = ((...args: any[]) => {
                const proto = Object.getPrototypeOf(this);  // Get prototype
                proto[PROTO_SYMBOL] = true;                 // Set flag to prevent returning a new wrapper function
                const protoFn = proto[propertyKey];         // Get original prototype function
                proto[PROTO_SYMBOL] = false;                // Clear flag
                return protoFn.apply(this, args);      // Call prototype function whith correct this and arguments
            }) as any as T;
            // Overwrite [propertyKey] function ONLY for [this] instance - not the prototype
            Object.defineProperty(this, propertyKey, {
                value: wrapper,
                configurable: true,
                writable: true,
            });
            return wrapper;
        }
    };
}

// export class AutobindTest extends React.Component {
//
//     private static testValue = '4';
//
//     @autobindOnAccessV2
//     public test_autobind() {
//         console.log('autobind:', AutobindTest.testValue);
//     }
//
//     @autobindLiveDecorator
//     public test_autobindLive() {
//         console.log('autobindLive:', AutobindTest.testValue);
//     }
//
//     public componentDidMount() {
//         setInterval(this.test_autobind, 4000);
//         setInterval(this.test_autobindLive, 4000);
//     }
//
//     public render(): React.ReactNode {
//         // debugger;
//         console.log('----');
//         this.test_autobind();
//         this.test_autobindLive();
//         return null;
//     }
// }
